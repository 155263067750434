//scss-lint:disable all
@import 'global/variables';

body {
    background-color: $smoke;

    &::before,
    &::after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    &::before {
        background-image: linear-gradient(90deg, rgba($yellow-text, 0.8) 0, rgba($yellow-text, 1) 30%);
        clip-path: polygon(0 55%, 40% 100%, 0 100%);
    }

    &::after {
        background-image: linear-gradient(to bottom, rgba($red-dark, 1) 50%, rgba($red, 0.7) 100%);
        clip-path: polygon(0 35%, 25% 100%, 0 100%);
        mix-blend-mode: multiply;
    }
}

.login {
    position: relative;
    z-index: 2;

    h1 a,
    .wp-login-logo a {
        background-color: transparent;
        background-image: url('../images/logo/mortarboard-vert-dark.svg');
        background-position: center center;
        background-size: contain;
        border: none;
        height: 100px;
        padding: 24px;
        width: 270px;
    }

    form {
        border-radius: 0.25rem;
        position: relative;
    }
}
